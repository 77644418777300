* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body, html {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
  font-family: 'Arial Black', sans-serif; /* Changed font-family */
}

.app-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.content-box {
  position: relative; /* Enable absolute positioning for child elements */
  background-color: #D8D2C2; /* Changed background color */
  border-radius: 20px;
  padding: 40px; /* Added padding around the content */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  width: 600px; /* Made wider */
  height: 500px; /* Increased height */
  text-align: left;
}

textarea.input-field {
  margin-bottom: 10px; /* Add space between input field and dropdown */
}

#model-select {
  width: 25%; /* Adjust the width of the dropdown */
  padding: 4px;
  border-radius: 8px;
  border: 1px solid #ccc;
  background-color: #fff;
  font-size: 15px;
  margin-left: 5px; /* Move the dropdown 10px to the right */
  margin-top: 5px; /* Optional: adjust space above the dropdown */
}

h1 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px; /* Adjusted margin */
  text-align: left; /* Align title to the left */
}

.input-field {
  width: 100%; /* Set to full width */
  padding: 15px; /* Padding for input */
  border: 1px solid #ccc; /* Added border */
  border-radius: 15px; /* Rounded corners */
  background-color: #e7f3fe; /* Light blue background */
  font-size: 16px;
  height: 290px; /* Adjusted height */
  box-shadow: inset 0px 2px 5px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s, box-shadow 0.3s; /* Transition effects */
  resize: none; /* Disable resizing */
  overflow: auto; /* Enable scrolling */
  display: block; /* Change to block to allow for wrapping */
  line-height: 1.4; /* Adjust line height for readability */
}

.input-field::placeholder {
  color: #888; /* Darker placeholder color */
  opacity: 0.8; /* Slightly transparent for a softer look */
}

.input-field:focus {
  outline: none; /* Remove default outline */
  border-color: #4A4947; /* Highlight border color */
  box-shadow: 0 0 5px #ffa50080; /* Highlight shadow */
}

.input-field:focus::placeholder {
  color: transparent; /* Hide placeholder on focus */
}

.drop-area {
  background-color: rgb(29, 29, 29); /* Same color as content box */
  border-radius: 20px;
  padding: 40px; /* Added padding around the content */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  width: 300px; /* Set width for the drop area */
  height: 500px; /* Match the height of the content box */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 20px; /* Space between the two boxes */
}

.file-drop-zone {
  border: 2px dashed #aaa; /* Dashed border */
  border-radius: 15px; /* Rounded corners */
  padding: 20px; /* Padding for the drop zone */
  width: 100%; /* Full width of the drop area */
  text-align: center; /* Centered text */
  cursor: pointer; /* Change cursor to pointer */
  transition: background-color 0.3s; /* Transition for hover effect */
  color: #aaa;
}

.file-drop-zone:hover {
  background-color: #e7f3fe; /* Light blue background on hover */
}

/* Spinner Icon */
.spinner {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.generate-btn {
  align-self: center;
  background-color: #4A4947;
  color: white;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 10px;
  font-size: 16px;
  border: none;
  transition: background-color 0.3s ease;
}

.generate-btn:disabled {
  background-color: #888;
  cursor: not-allowed;
}

.file-support-text {
  font-size: 12px; /* Smaller font size */
  color: #888; /* Matching the color of the rest of the text */
  margin-top: 10px; /* Space between the "Прикрепите файлы" and the new text */
}

.italic-text {
  font-style: italic; /* Italicized only for the extension */
}
